
import GridLoader from "react-spinners/GridLoader";
import { useState, CSSProperties } from "react";
import './App.css';
import React, { Suspense, lazy } from 'react';
import "leaflet/dist/leaflet.css";


import {
  BrowserRouter as Router,
  Route
 
  
} from "react-router-dom";

const Home = lazy(()=>import('./Home'))
const Feed = lazy(()=>import('./Feed'))
const Ayuda = lazy(()=>import('./Ayuda'))
const Notificaciones = lazy(()=>import('./Notificaciones'))
const PoliticasPrivacidad = lazy(()=>import('./PoliticasPrivacidad'))
const CrearPromo = lazy(()=>import('./CrearPromo'))
const Contactanos = lazy(()=>import('./Contactanos'))
const QuejasReclamos = lazy(()=>import('./QuejasReclamos'))
const Perfil = lazy(()=>import('./Perfil'))
const Registro = lazy(()=>import('./Registro'))
const RegistroRate = lazy(()=>import('./RegistroRate'))
const Resultados = lazy(()=>import('./Resultados'))
const TerminosServicio= lazy(()=>import('./TerminosServicio'))
const IniciarSesion= lazy(()=>import('./IniciarSesion'))
const IniciarSesionRate= lazy(()=>import('./IniciarSesionRate'))
const RegistroIndex= lazy(()=>import('./RegistroIndex'))
const Reports= lazy(()=>import('./Reports'))
const Publicar= lazy(()=>import('./Publicar'))
const Products= lazy(()=>import('./Products'))
const ExitoPublicado= lazy(()=>import('./ExitoPublicado'))
const Editar= lazy(()=>import('./Editar'))
const ResetPassword= lazy(()=>import('./ResetPassword'))
const ResetPasswordR= lazy(()=>import('./ResetPasswordR'))
const NewPassword= lazy(()=>import('./NewPassword'))
const NewCorreo= lazy(()=>import('./NewCorreo'))
const NewPhone= lazy(()=>import('./NewPhone'))
const PagoExitoso= lazy(()=>import('./PagoExitoso'))
const  PrivateRoute= lazy(()=>import('./PrivateRoute'))
const  PrivateRouteRate= lazy(()=>import('./PrivateRouteRate'))
const  userDashboard= lazy(()=>import('./userDashboard'))
const  PublicRoute= lazy(()=>import('./PublicRoute'))
const  ResultadoDetalle= lazy(()=>import('./ResultadoDetalle'))
const  ReportarProblema= lazy(()=>import('./ReportarProblema'))
const  ResultadoBlank= lazy(()=>import('./ResultadoBlank'))
const  TopPost= lazy(()=>import('./TopPost'))
const  Rate= lazy(()=>import('./Rate'))
const   PauteAqui = lazy(()=>import('./PauteAqui'))
const   TerminosConcurso = lazy(()=>import('./TerminosConcurso'))
const   Tutoriales = lazy(()=>import('./Tutoriales'))
const   createCodes = lazy(()=>import('./CreateCodes'))
const   InstructivoConcurso = lazy(()=>import('./InstructivoConcurso'))
const   Profile = lazy(()=>import('./Profile'))
const   PerfilDesactivado = lazy(()=>import('./PerfilDesactivado'))
const override: CSSProperties = {
  display:"block",
  marginTop:"300px",
  marginBottom:"auto"

};



function App() {




  
  return (
    <Suspense fallback={<div style={{display:"flex", alignItems:"center",justifyContent:"center"}}><GridLoader color={"green"} loading={true} cssOverride={override} size={15} /></div>}>
    <Router forceRefresh={true}>
       
    <div className="app">
   
    <Route path="/api/users/reset-password/:_id/:_token" component={NewPassword}/> 
    <Route path="/api/users/reset-correo/:_id/:_token" component={NewCorreo}/> 
    <Route path="/api/users/reset-tel/:_id/:_token" component={NewPhone}/> 
    <Route path="/editar/:_id" component={Editar}/> 
    <Route path="/resultado" component={ResultadoBlank}/> 
    <Route path="/resultado/:_id" component={ResultadoDetalle}/> 
    
   
   <PublicRoute restric={true} path="/iniciarSesionRate" component={IniciarSesionRate}/>  
      <Route path="/ayuda" component={Ayuda}/> 
      <Route path="/politicasPrivacidad" component={PoliticasPrivacidad}/> 
      <Route path="/instructivoConcurso" component={InstructivoConcurso}/> 
      <Route path="/tutoriales" component={Tutoriales}/> 
      <Route path="/reportarProblema" component={ReportarProblema}/> 
      <Route path="/createCodes" component={createCodes}/> 
      <Route path="/terminosConcurso" component={TerminosConcurso}/> 
      <Route path="/pauta" component={PauteAqui}/> 
      <Route path="/pagoExitoso" component={PagoExitoso}/> 
      <Route path="/contactanos" component={Contactanos}/> 
      <Route path="/quejas" component={QuejasReclamos}/> 
      <Route path="/registro" component={Registro}/> 
      <Route path="/registroRate" component={RegistroRate}/> 
      <Route path="/resetPassword" component={ResetPassword}/> 
      <Route path="/resetPasswordR" component={ResetPasswordR}/> 
      
      <Route path="/resultados" render={(props) => <Resultados {...props}/>}/>
      <Route path="/terminos" component={TerminosServicio}/>
      <Route path="/perfilDesactivado" component={PerfilDesactivado}/>
      <Route path="/registroIndex" component={RegistroIndex}/>  
      <Route path='/topPost' component={TopPost} />
     
      <PublicRoute restric={true} path="/iniciarSesion" component={IniciarSesion}/>
      <PrivateRouteRate restrict={true} path="/milugar/:userName" component={Profile}/> 
  
      <PrivateRoute restric={true} path='/publicar' component={Publicar} /> 
      <PrivateRoute restric={true} path='/Notificaciones' component={Notificaciones} /> 
      <PrivateRoute restric={true} path='/products' component={Products} />
      <PrivateRoute restric={true} path='/reports' component={Reports} />
      <PrivateRoute restric={true} path='/feed' component={Feed} />
      <PrivateRoute restric={true} path='/crearPromo' component={CrearPromo} />
      <PrivateRoute restric={true} path='/perfil' component={Perfil} />
      <PrivateRoute restric={true} path='/exitoPublicado' component={ExitoPublicado} />
      <PrivateRoute restric={true} component={userDashboard} path="/userDashboard" exact/>
      <PrivateRouteRate restric={true} component={Rate} path="/rate/:userName" exact/>
      


      <Route  exact path="/" component={Home}/>
     
    </div>
    </Router>
    </Suspense>
  );
}

export default App;
